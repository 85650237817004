import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NO ENCONTRADO</h1>
    <p>Al parecer la pagina que busca no se encuentra disponible.</p>
  </Layout>
)

export default NotFoundPage
